@import './assets/styles/font-face.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom react-date-range DateRange style */
.rdrMonth {
  width: 100% !important;
}

@keyframes slideIn {
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@layer base {
  html,
  body {
    font-family: 'ibmplexsans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
  }
  select {
    @apply py-1 text-sm bg-white border border-grey rounded-md;
  }

  .invalid-input button,
  .invalid-input input,
  .invalid-input select {
    @apply ring-2 ring-red-600;
  }

  table td,
  table th {
    @apply px-2 py-1;
  }
  table thead {
    @apply text-primary font-medium bg-gray-200;
  }
  table th {
    @apply text-left font-medium;
  }
  table tbody tr:nth-child(even) {
    @apply bg-gray-50;
  }
  .custom-react-tooltip {
    @apply text-base border border-gray-200 shadow-xl !important;
  }
  .custom-react-tooltip.show {
    @apply opacity-100 !important;
  }
  /**
   * Truncate text on 2nd line with ellipsis
   */
  .truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .slideIn {
    transform: translateX(200%);
    animation: slideIn 200ms ease-in forwards;
  }
  .fadeOut {
    animation: fadeOut 250ms linear forwards;
  }
  .fadeIn {
    animation: fadeIn 250ms linear forwards;
  }
}
