@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-thin-italic.woff2') format('WOFF2');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-thin.woff2') format('WOFF2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-semibold-italic.woff2') format('WOFF2');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-semibold.woff2') format('WOFF2');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-regular.woff2') format('WOFF2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-medium-italic.woff2') format('WOFF2');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-medium.woff2') format('WOFF2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-light-italic.woff2') format('WOFF2');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-light.woff2') format('WOFF2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-italic.woff2') format('WOFF2');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-extralight-italic.woff2') format('WOFF2');
  font-style: italic;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-extralight.woff2') format('WOFF2');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-bold-italic.woff2') format('WOFF2');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'ibmplexsans';
  src: url('../fonts/ibmplexsans-bold.woff2') format('WOFF2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
